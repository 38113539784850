
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";
import { ElNotification, ElMessage } from "element-plus";

import { DatabaseMemberAccessList } from "@/models/server/IServerMember";
export default defineComponent({
  name: "modal-member-access",
  props: {
    selectedMemberAccess: Object as () => DatabaseMemberAccessList[],
    selectedMemberId: String as () => string,
    wildcardList: Object as () => string[],
  },
  components: {},
  data() {
    return {
      modalRef: "memberAccess",
      isEdit: ref<boolean>(false),
      editButtonText: ref<string>("Edit"),
      selectedData: this.selectedMemberAccess || [],
      wildcardListData: this.wildcardList || [],
      addedWildcard: ref<string[]>([]),
      deletedWildCard: ref<string[]>([]),
      wildcardInput: ref<string>(""),
    };
  },
  methods: {
    wildcardOption() {
      return this.wildcardListData?.map((el) => {
        return {
          label: el,
          value: el,
        };
      });
    },
    editMode() {
      this.isEdit = !this.isEdit;
      this.editButtonText = this.isEdit ? "Cancel" : "Edit";
    },
    onAddItem() {
      const addedData = {
        no: this.selectedData.length + 1,
        access_grant: this.selectedData[0]?.access_grant,
        access_grant_array: this.selectedData[0]?.access_grant_array,
        wildcard: this.wildcardInput,
      };
      if (this.wildcardInput) {
        this.addedWildcard.push(this.wildcardInput);
        this.selectedData.push(addedData);
        this.wildcardInput = "";
      }
    },
    deleteRow(index: number) {
      if (this.selectedData.length > 0) {
        this.deletedWildCard.push(this.selectedData[index].wildcard);
        this.selectedData.splice(index, 1);
      }
    },
    async onSubmit() {
      const addPayload = this.addedWildcard.map((el) => {
        return {
          grant: this.selectedData[0]?.access_grant,
          wildcard: el,
          action: 1,
        };
      });
      const deletedPayload = this.deletedWildCard.map((el) => {
        return {
          grant: this.selectedData[0]?.access_grant,
          wildcard: el.replaceAll("`", ""),
          action: 2,
        };
      });

      const payloads = {
        payload: addPayload.concat(deletedPayload),
      };
      try {
        const submitData = await ApiService.vueInstance.axios.put(
          `database/v1/server/${this.$route.params.serverId}/member/${this.selectedMemberId}/edit`,
          payloads
        );
        if (submitData.data.data.success) {
          ElNotification({
            title: "Success",
            message: "Update user success.",
            type: "success",
          });
        }
        hideModal(this.$refs[this.modalRef] as HTMLElement);
        this.resetData();
      } catch (error) {
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
    resetData() {
      this.wildcardInput = "";
      this.isEdit = false;
      this.addedWildcard = [];
      this.deletedWildCard = [];
    },
  },
});
