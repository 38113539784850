
import { defineComponent, ref } from "vue";
import { ElMessage } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import { Reading } from "@element-plus/icons-vue";

import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import {
  IServerMember,
  DatabaseMemberAccessList,
} from "@/models/server/IServerMember";
import MemberAccess from "@/components/modals/general/MemberAccess.vue";

export default defineComponent({
  name: "server-member-list",
  emits: ["deletedServerMembers"],
  props: {
    cardClasses: String,
    memberList: Object as () => IServerMember[],
    wildcardList: Object as () => string[],
  },
  components: {
    Datatable,
    Reading,
    MemberAccess,
  },
  data() {
    return {
      tableHeader: ref([
        {
          key: "checkbox",
          sortable: false,
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Email",
          key: "email",
          sortable: true,
        },
        {
          name: "Username",
          key: "username",
          sortable: true,
        },
        {
          name: "Action",
          key: "action",
        },
      ]),
      checkedMembers: ref<number[]>([]),
      selectedMemberAccess: ref<DatabaseMemberAccessList[]>([]),
      selectedMemberId: ref<string>(""),
      search: "",
      userType: ref<number>(0),
      initialData: ref<Array<IServerMember>>([]),
    };
  },
  methods: {
    deleteMembersFromServer() {
      const params = {
        member_id: this.checkedMembers,
      } as AxiosRequestConfig;

      ApiService.post(
        "database/v1/server/" + this.$route.params.serverId + "/remove",
        params
      ).then(() => {
        this.checkedMembers.forEach((el) => {
          this.deleteMember(el);
        });
        this.$emit("deletedServerMembers");
        this.checkedMembers = [];
      });
    },
    deleteMember(id) {
      if (!this.members) return;
      const list = this.$store.getters.currentServer
        .member_list as IServerMember[];
      for (let i = 0; i < list.length; i++) {
        if (list[i].member_id === id) {
          this.members.splice(i, 1);
        }
      }
    },
    async showMemberAccess(memberlist: IServerMember) {
      try {
        const userDetail = await ApiService.get(
          `database/v2/member/${memberlist.member_id}/detail`
        );
        const databaselist = userDetail.data.data.server_list.filter((el) => {
          return el.server_id == Number(this.$route.params.serverId);
        });

        const databaselistProcess: DatabaseMemberAccessList[] =
          databaselist[0].database_member_access_list.map((el, idx) => {
            return {
              no: idx + 1,
              access_grant: el.access_grant,
              access_grant_array: el.access_grant.split(","),
              wildcard: el.wildcard,
            };
          });
        this.selectedMemberAccess.splice(
          0,
          this.selectedMemberAccess.length,
          ...databaselistProcess
        );
        this.selectedMemberId = `${memberlist.member_id}`;
      } catch (error) {
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
    keyMatches(obj, value): boolean {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && typeof obj[key] === "string") {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    searchMember() {
      this.members.splice(0, this.members.length, ...this.initialData);
      if (this.search !== "") {
        let results: Array<IServerMember> = [];
        for (let i = 0; i < this.members.length; i++) {
          if (this.keyMatches(this.members[i], this.search)) {
            results.push(this.members[i]);
          }
        }
        this.members.splice(0, this.members.length, ...results);
      }
    },
  },
  watch: {
    userType(memberType: number) {
      if (memberType === 0) {
        this.members.splice(0, this.members.length, ...this.initialData);
        return;
      }
      const memberTypeString = memberType == 1 ? "Employee" : "System";
      const filteredData = this.initialData.filter(
        (el) => el.member_type.name === memberTypeString
      );
      this.members.splice(0, this.members.length, ...filteredData);
    },
  },
  computed: {
    members: function (): IServerMember[] {
      if (!this.$props.memberList) return [];
      return this.$props.memberList;
    },
  },
  mounted() {
    if (this.$props.memberList) {
      const processedMemberList = this.$props.memberList;
      this.members.splice(
        0,
        processedMemberList.length,
        ...processedMemberList
      );
      this.initialData.splice(
        0,
        processedMemberList.length,
        ...processedMemberList
      );
    }
  },
});
