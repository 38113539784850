
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddServerMemberModal from "@/components/modals/forms/AddServerMemberModal.vue";
import { IServerMember } from "@/models/server/IServerMember";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";

interface IDbGroupWildcards {
  id: number;
  database_server_id: number;
  name: string;
}

export default defineComponent({
  name: "server-non-member-list",
  emits: ["addedNewServerMember"],
  props: {
    cardClasses: String,
    memberList: Object as () => IServerMember[],
    wildcardList: Object as () => string[],
    selectedNonMembers: Object as () => number[],
  },
  components: {
    Datatable,
    AddServerMemberModal,
  },
  data() {
    return {
      checkedMembers: ref([]),
      nonMembers: ref<Array<IServerMember>>([]),
      tableHeader: ref([
        {
          key: "checkbox",
          sortable: false,
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Email",
          key: "email",
          sortable: true,
        },
        {
          name: "Username",
          key: "username",
          sortable: true,
        },
      ]),
      search: "",
      initialData: ref<Array<IServerMember>>([]),
      dbGroupWildcards: ref<IDbGroupWildcards[]>([]),
      userType: ref<number>(0),
    };
  },
  methods: {
    updateList() {
      this.checkedMembers = [];
      this.$emit("addedNewServerMember");
      ElMessage.success("Success add member to server");
    },
    keyMatches(obj, value): boolean {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && typeof obj[key] === "string") {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    searchMember() {
      this.nonMembers.splice(0, this.nonMembers.length, ...this.initialData);
      if (this.search !== "") {
        let results: Array<IServerMember> = [];
        for (let i = 0; i < this.nonMembers.length; i++) {
          if (this.keyMatches(this.nonMembers[i], this.search)) {
            results.push(this.nonMembers[i]);
          }
        }
        this.nonMembers.splice(0, this.nonMembers.length, ...results);
      }
    },
    errorHandler(errorData) {
      if (errorData instanceof Error) {
        // ✅ TypeScript knows err is Error
        ElMessage.error(errorData.message);
      } else {
        ElMessage.error("Unexpected error");
      }
    },
    async getDbGroupWildcard() {
      try {
        const response = await ApiService.vueInstance.axios.get(
          `database-group/v1/list?db_server_id=${this.$route.params.serverId}`
        );
        if (response.data.data) {
          this.dbGroupWildcards.splice(
            0,
            this.dbGroupWildcards.length,
            ...response.data.data
          );
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async setNonMemberList(memberType: number) {
      try {
        const memberMap = new Map();
        this.$store.getters.currentServer.member_list?.forEach((el) =>
          memberMap.set(el.member_id, el.member_id)
        );

        const allMember = await ApiService.vueInstance.axios.get(
          `database/v1/member/list?member_type=${memberType}`
        );
        const nonMemberList = allMember.data.data.filter(
          (member) => memberMap.get(member.member_id) === undefined
        );

        this.nonMembers.splice(0, this.nonMembers.length, ...nonMemberList);
        this.initialData.splice(0, this.nonMembers.length, ...nonMemberList);
      } catch (error) {
        this.errorHandler(error);
      }
    },
  },
  watch: {
    userType(memberType: number) {
      this.setNonMemberList(memberType);
    },
    memberList() {
      this.setNonMemberList(this.userType);
    },
  },
  mounted() {
    this.setNonMemberList(0);
  },
  setup() {
    return {};
  },
});
