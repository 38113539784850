export interface IAccessGroup {
  access: string[];
  type: string;
  value: string;
}

export const ServerMemberAccess: string[] = [
  "EXECUTE",
  "PROCESS",
  "SELECT",
  "SHOW DATABASES",
  "SHOW VIEW",
  "ALTER",
  "ALTER ROUTINE",
  "CREATE",
  "CREATE TEMPORARY TABLES",
  "CREATE ROUTINE",
  "CREATE TABLESPACE",
  "CREATE VIEW",
  "DELETE",
  "EVENT",
  "INDEX",
  "INSERT",
  "REFERENCES",
  "TRIGGERS",
  "UPDATE",
  "CREATE USER",
  "FILE",
  "GRANT",
  "LOCK TABLES",
  "RELOAD",
  "REPLICATION CLIENT",
  "REPLICATION SLAVE",
  "SHUTDOWN",
  "SUPER",
];

export const ServerMemberAccessGroup: IAccessGroup[] = [
  {
    access: [
      "SELECT",
      "CREATE",
      "ALTER",
      "INSERT",
      "UPDATE",
      "INDEX",
      "TRIGGER",
    ],
    type: "Employee Deployment",
    value: "EmployeeDeployment",
  },
  {
    access: ["SELECT"],
    type: "Employee Viewer",
    value: "EmployeeViewer",
  },
  {
    access: [
      "SELECT",
      "CREATE",
      "ALTER ",
      "INSERT",
      "UPDATE",
      "INDEX",
      "TRIGGER",
      "REFERENCES",
      "EXECUTE",
      "PROCESS",
    ],
    type: "Apps (%)",
    value: "Apps%",
  },
  {
    access: [
      "SELECT",
      "CREATE",
      "ALTER",
      "INSERT",
      "UPDATE",
      "INDEX",
      "TRIGGER",
      "REFERENCES",
      "EXECUTE",
      "PROCESS",
      "CREATE ROUTINE",
      "ALTER ROUTINE",
    ],
    type: "Apps Migrations (%)",
    value: "AppsMigration%",
  },
  {
    access: ["SELECT"],
    type: "Apps Viewer (%)",
    value: "AppsViewer%",
  },
];
