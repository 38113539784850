
import { defineComponent, ref } from "vue";
import { ElMessage } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import { Actions } from "@/store/enums/StoreEnums";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ServerMemberList from "@/components/servers/members/ServerMemberList.vue";
import ServerNonMemberList from "@/components/servers/members/ServerNonMemberList.vue";
import { IServer } from "@/models/server/IServer";

export default defineComponent({
  name: "server-details",
  components: {
    ServerMemberList,
    ServerNonMemberList,
  },
  data() {
    return {
      selectedMembers: ref([] as number[]),
      selectedNonMembers: ref([] as number[]),
      memberList: ref<string[]>([]),
      wildcardList: ref<string[]>([]),
    };
  },
  methods: {
    updateNonMemberList(list: number[]) {
      this.selectedNonMembers.splice(0, list.length, ...list);
    },
    async getWildCard() {
      try {
        const wildcardList = await ApiService.vueInstance.axios.get(
          `database/v1/server/${this.$route.params.serverId}/wildcard/list`
        );
        wildcardList.data.data.push("*");

        this.wildcardList.splice(
          0,
          this.wildcardList.length,
          ...wildcardList.data.data
        );
      } catch (error) {
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
  },
  watch: {
    server() {
      this.memberList.splice(
        0,
        this.memberList.length,
        ...this.$store.getters.currentServer.member_list
      );
    },
  },
  computed: {
    server: function (): IServer {
      return this.$store.getters.currentServer;
    },
    userEmail: function (): string {
      return this.$store.getters.currentUser?.email;
    },
  },
  mounted() {
    setCurrentPageBreadcrumbs("Server Details", []);
    this.getServerDetail();
    this.getWildCard();
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const getServerDetail = () => {
      store.dispatch(Actions.GET_SERVER_DETAIL, route.params.serverId);
    };

    getServerDetail();

    return {
      getServerDetail,
    };
  },
});
