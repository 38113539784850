
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { INewServerMember } from "@/models/server/INewServerMember";
import ApiService from "@/core/services/ApiService";
import {
  ServerMemberAccess,
  ServerMemberAccessGroup,
} from "@/constants/ServerMemberAccess";
import { AxiosRequestConfig } from "axios";
import { ElMessage } from "element-plus";

interface IDbGroupWildcards {
  id: number;
  database_server_id: number;
  name: string;
}
interface IDbWildcard {
  id: number;
  database_group_id: number;
  wildcard: string;
}
interface IWildcardListData {
  name: string;
  id: number;
}

export default defineComponent({
  name: "add-server-member-modal",
  emits: ["addedNewServerMember", "successAddMember"],
  components: {},
  props: {
    selectedNonMembers: Object as () => number[],
    wildcardList: Object as () => IWildcardListData[],
    dbGroupWildcards: Object as () => IDbGroupWildcards[],
  },
  data() {
    return {
      addServerMemberModalRef: "addServerMemberModalRef",
      checkAll: ref<boolean>(false),
      checkedAccess: ref([] as string[]),
      accessList: ServerMemberAccess,
      accessGroupList: ServerMemberAccessGroup,
      formData: ref<INewServerMember>({
        member_id: [],
        access_grant: "",
        wildcard: [],
      }),
      memberList: ref([] as number[]),
      formRef: "addServerMember",
      isIndeterminate: ref<boolean>(false),
      loading: ref<boolean>(false),
      selectedAccessGroup: ref<string>(""),
      active: ref<number>(0),
      modalButton: ref<string>("Next"),

      wildcardState: ref<string[]>([]),
      selectedWildcard: ref<string[]>([]),
      wildcardRadio: ref<string>("all"),

      selectedDbGroup: ref<string>(""),
      dbwildcard: ref<IDbWildcard[]>([]),

      isCustom: ref<string>("Pre Defined"),
      emailCc: ref<string>(""),
    };
  },
  methods: {
    selectGroup(selectedData: string[]) {
      this.checkedAccess.splice(0, this.checkedAccess.length, ...selectedData);
    },
    addMembersToServer(params) {
      ApiService.post(
        `database/v1/server/${this.$route.params.serverId}/member/insert`,
        params as AxiosRequestConfig
      )
        .then(() => {
          this.checkAll = false;
          this.checkedAccess = [];
          this.isIndeterminate = false;
          this.selectedAccessGroup = "";
          this.formData.wildcard = [];

          hideModal(this.$refs[this.addServerMemberModalRef] as HTMLElement);
          this.$emit("addedNewServerMember");
          this.$emit("successAddMember");
        })
        .catch((err) => {
          Swal.fire({
            title: err.response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
      this.loading = false;
    },
    handleCheckAllChange(val: boolean) {
      this.checkedAccess = val ? this.accessList : [];
      this.isIndeterminate = false;
    },
    handleCheckedAccessChange() {
      const checkedCount = this.checkedAccess.length;
      this.checkAll = checkedCount === this.accessList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.accessList.length;
    },
    closeModal() {
      this.checkAll = false;
      this.checkedAccess = [];
      this.isIndeterminate = false;
      this.selectedAccessGroup = "";
      this.formData.wildcard = [];
      this.active = 0;
      this.wildcardRadio = "all";

      hideModal(this.$refs[this.addServerMemberModalRef] as HTMLElement);
    },
    back() {
      this.active--;
    },
    next() {
      if (this.active >= 3) {
        let params = {
          member_id: this.selectedNonMembers,
          grant: this.checkedAccess.join(),
          wildcard: ["*"],
          email_ccs: this.mapEmailCc,
        };
        if (this.wildcardRadio === "group")
          params.wildcard = this.dbwildcard.map((el) => el.wildcard);
        if (this.wildcardRadio === "custom")
          params.wildcard = this.wildcardState;

        this.addMembersToServer(params);
        this.closeModal();
        this.active = 0;
        this.modalButton = "Next";
      } else if (this.active === 2) {
        this.active++;
        this.modalButton = "Submit";
        if (this.wildcardRadio === "all") {
          this.selectedWildcard.splice(0, this.selectedWildcard.length, "*");
        }
      } else {
        this.active++;
      }
    },
    selectCustom(access) {
      let updateList = [...this.checkedAccess];
      if (updateList.includes(access))
        updateList = updateList.filter((el) => el !== access);
      else updateList.push(access);
      this.checkedAccess.splice(0, this.checkedAccess.length, ...updateList);
    },
    resetAccess(flag) {
      if (flag === 1) {
        this.checkedAccess = [];
        this.checkAll = false;
        this.isIndeterminate = false;
      } else {
        this.selectedAccessGroup = "";
      }
    },
    initialMounted() {
      this.checkAll = false;
      this.checkedAccess = [];
      this.formData = {
        member_id: [],
        access_grant: "",
        wildcard: [],
      };
      this.selectedAccessGroup = "";
      this.active = 0;
      this.modalButton = "Next";

      this.wildcardState = [];
      this.selectedWildcard = [];

      this.isCustom = "Pre Defined";
      this.wildcardRadio = "all";
    },
    async getDbWildcard(value) {
      const groupId = this.dbGroupWildcards && this.dbGroupWildcards[value].id;
      try {
        const response = await ApiService.vueInstance.axios.get(
          `database-group/wildcard/v1/list?db_group_id=${groupId}`
        );
        this.dbwildcard.splice(
          0,
          this.dbwildcard.length,
          ...response.data.data
        );
      } catch (error) {
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
  },
  mounted() {
    if (this.selectedNonMembers) {
      this.memberList.splice(
        0,
        this.selectedNonMembers.length,
        ...this.selectedNonMembers
      );
    }
    this.initialMounted();
  },
  watch: {
    wildcardRadio(value) {
      if (value == "custom") this.wildcardState = [];
      if (value == "group") {
        this.selectedDbGroup = "";
        this.dbwildcard.splice(0);
      }
    },
    selectedDbGroup(value) {
      this.getDbWildcard(value);
    },
  },
  computed: {
    isIndeterminedChange: function () {
      let checkedCount = this.checkedAccess.length;
      return checkedCount > 0 && checkedCount < this.accessList.length;
    },
    isCheckAll: function () {
      let checkedCount = this.checkedAccess.length;
      return checkedCount === this.accessList.length;
    },
    mapEmailCc() {
      if (this.emailCc) {
        return this.emailCc.split(",").map((el) => el.trim());
      }
      return [];
    },
  },
});
